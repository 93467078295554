<template>
  <div class="first">
    <div class="flex_between bbe9s">
      <div class="title f-left p10">基础方案列表</div>
    </div>
    <!-- 任务查找与筛选 -->
    <div class="mt10">
      <div class="block">
        <!-- <div :style="{ display: isindex[1].tt == 't1' ? 'none' : '' }"> -->
        <div class="flex_warp pb20" style="overflow: inherit !important">
          <!-- 方案名称 -->
          <div class="blocks2 flex_start flex_items">
            <span class="mr20 smallTit">方案名称</span>
            <el-input
              placeholder="请输入"
              v-model="planName"
              clearable
              class="littleBox w-160"
            >
            </el-input>
          </div>
          <!-- 方案类型 -->
          <div class="blocks2 flex_start flex_items">
            <span class="mr20 smallTit">方案类型</span>
            <el-select
              v-model="planType"
              placeholder="方案类型"
              :clearable="true"
              filterable
              class="littleBox w-160"
            >
              <el-option
                v-for="(item, index) in planTypes"
                :key="index"
                :label="item.title"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <!-- 创建人 -->
          <div class="blocks2 flex_start flex_items">
            <span class="mr20 smallTit">创建人</span>
            <el-input
              placeholder="请输入"
              v-model="planCreater"
              clearable
              class="littleBox w-160"
            >
            </el-input>
          </div>
          <!-- 创建时间 -->
          <div class="blocks2 flex_items flex_start">
            <span class="mr20 smallTit">创建时间</span>
            <el-date-picker
              v-model="create_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              class="datePicker"
              :default-time="['00:00:00', '23:59:59']"
            ></el-date-picker>
          </div>
          <!-- 状态 -->
          <div class="blocks2 flex_start flex_items">
            <span class="mr20 smallTit">状态</span>
            <el-select
              v-model="state"
              placeholder="筛选状态"
              :clearable="true"
              filterable
              class="littleBox w-160"
            >
              <el-option
                v-for="(item, index) in states"
                :key="index"
                :label="item.title"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <!-- 筛选 -->
          <div class="blocks2 createPlanPr">
            <el-button
              type="primary"
              size="small"
              plain
              class="mr5"
              @click="createPlan"
              >创建方案</el-button
            >
            <el-button
              type="primary"
              size="small"
              plain
              class="mr5"
              @click="copyPlan"
              >复制创建</el-button
            >
            <el-button
              type="primary"
              size="small"
              plain
              class="mr5 m-l-100"
              @click="clearSearch"
              >重置</el-button
            >
            <el-button type="primary" size="small" plain @click="shaixuan"
              >查询</el-button
            >
          </div>
        </div>
        <!-- </div> -->
      </div>
    </div>
    <div>
      <avue-crud :option="option" :data="planData">
        <template slot-scope="scope" slot="relation">
          <div class="two">
            {{ scope.row.relation }}
          </div>
        </template>
        <template slot-scope="scope" slot="status">
          <el-button
            type="success"
            plain
            v-if="scope.row.status == '启用'"
            size="mini"
            disabled
            >启用</el-button
          >
          <el-button type="danger" plain v-else size="small" disabled
            >禁用</el-button
          >
        </template>
        <template
          slot-scope="{ row, type, size }"
          slot="menu"
          style="width: 200px"
        >
          <el-button :size="size" :type="type" @click="edit(row)"
            >编辑</el-button
          >
          <el-button
            :size="size"
            :type="type"
            @click="changeStatus(row, 1)"
            v-if="row.status == '禁用'"
            >启用</el-button
          >
          <el-button
            :size="size"
            :type="type"
            @click="changeStatus(row, 2)"
            v-if="row.status == '启用'"
            >禁用</el-button
          >
          <el-button :size="size" :type="type" @click="lookDetail(row)"
            >详情</el-button
          >
        </template>
      </avue-crud>
      <!-- 页码 -->
      <div class="flex_start mt10 flex_items ml15">
        <span class="demonstration mr20">
          总共
          <span style="color: red">{{ count }}</span
          >条数据,当前 <span style="color: red">{{ planData.length }}</span
          >条
        </span>
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="20"
          :total="count"
          layout="prev, pager, next, jumper"
        ></el-pagination>
      </div>
    </div>

    <!-- 弹框 -->
    <div v-if="isdiag">
      <el-dialog
        :visible.sync="dialogTableVisible"
        append-to-body
        :modal-append-to-body="false"
        :close-on-click-modal="false"
        width="80%"
        @open="diagopen()"
        @close="diagclose()"
        :key="keyNum"
      >
        <AddPlan
          v-if="dialogname == 'addPlan'"
          :currentPage.sync="currentPage"
          @getDataAgain="getDataAgain"
        ></AddPlan>
        <EditPlan
          v-if="dialogname == 'editPlan'"
          :currentPage.sync="currentPage"
          @getDataAgain="getDataAgain"
        ></EditPlan>
        <PlanDet
          v-if="dialogname == 'planDet'"
          :currentPage.sync="currentPage"
          @getDataAgain="getDataAgain"
        ></PlanDet>
        <PlanDetCopy
          v-if="dialogname == 'planDetCopy'"
          :currentPage.sync="currentPage"
          @getDataAgain="getDataAgain"
        ></PlanDetCopy>
      </el-dialog>
    </div>
    <!-- 复制方案 -->
    <div>
      <el-dialog
        title="复制创建"
        :visible.sync="dialogTableVisible2"
        append-to-body
        :close-on-click-modal="false"
        width="25%"
        @close="copyClose()"
      >
        <el-input
          v-model="planNum"
          placeholder="请输入方案编号，快速创建"
          style="margin-bottom: 30px; width: 100%"
        ></el-input>
        <div>
          <el-button @click="cancelCopy" style="margin-left: 30%"
            >取 消</el-button
          >
          <el-button type="primary" @click="submitCopy" plain>确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
// import assign from "@/components/Assign.vue";
export default {
  data() {
    return {
      keyNum: 1,
      planName: "",
      planType: "",
      planTypes: [
        {
          id: 1,
          title: "防水补漏",
        },
        {
          id: 2,
          title: "局部装修改造",
        },
      ],
      planCreater: "",
      create_time: "",
      planData: [
        // {
        //     plan_id:123456789,
        //     plan_name:"阳台改造",
        //     plan_type:"墙面翻新",
        //     check_result:"可以给一个与",
        //     useNum:23,
        //     creater:"小红",
        //     manage_time:"2021-08-12 12:56:12",
        //     state:0
        // },
      ],
      option: {
        border: true,
        size: "medium",
        // menu: false,
        width: "90%",
        page: false,
        align: "left",
        dateBtn: false,
        dateDefault: false,
        addBtn: false,
        delBtn: false,
        editBtn: false,
        columnBtn: false, //列显隐按钮
        refreshBtn: false, //刷新按钮
        column: [
          {
            label: "方案ID",
            prop: "plan_id",
            width: "70px",
          },
          {
            label: "方案编号",
            prop: "scheme_no",
          },
          {
            label: "方案名称",
            prop: "name",
          },
          {
            label: "方案类型",
            prop: "category_name",
          },
          {
            label: "关联检测结果",
            prop: "relation",
            slot: true,
          },
          {
            label: "使用次数",
            prop: "use",
            width: "80px",
          },
          {
            label: "创建人",
            prop: "username",
            width: "60px",
          },
          {
            label: "创建时间",
            prop: "creationTime",
          },
          {
            label: "方案状态",
            prop: "status",
            width: "80px",
            slot: true,
          },
        ],
      },
      state: "",
      states: [
        {
          id: 1,
          title: "启用",
        },
        {
          id: 2,
          title: "禁用",
        },
      ],
      isdiag: false,
      isheight: "",
      activeNames: ["1"],
      // 任务状态
      tasks: [],
      TaskStatus: "",
      time1: "",
      chosecon: "",
      selectkind: "",
      client: "",
      clienton: "",
      citys: [],
      genjin: [],
      jiedan: "",
      citykind: "",
      taskid: "",
      //   物业
      wuyeVal: "",
      wuyeid: "",
      wuyekuang: false,
      nowuye: false,
      items: [],
      // 分页数据
      currentPage: 1,
      planData: [],
      count: 0,
      // 弹框
      dialogTableVisible: false,
      dialogname: "add",
      task: 0,
      shoppers: [],
      shopper: "",
      dialogTableVisible2: false,
      wechat: "",
      shengid: "",
      details: {
        province_id: "",
        city_id: "",
        county_id: "",
      },
      adminUsername: null,
      planNum: "",
    };
  },
  mounted() {
    this.clienton = sessionStorage.getItem("admin");
    this.getLists();
    this.getChoice();
  },
  methods: {
    // 获取方案类别
    getChoice() {
      this.util.get(this.HOST + "/Diagnostic/all", {}).then((res) => {
        if (res.code == 200) {
          this.planTypes = res.data.label;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    },
    createPlan() {
      this.$store.commit("setDialog", true);
      this.dialogname = "addPlan";
    },
    edit(row) {
      this.$store.commit("setPlan", row);
      this.$store.commit("setDialog", true);
      this.dialogname = "editPlan";
    },
    ban(row) {},
    lookDetail(row) {
      this.$store.commit("setDialog", true);
      this.$store.commit("setPlan", row);
      this.dialogname = "planDet";
    },
    copyPlan() {
      this.dialogTableVisible2 = true;
    },
    clearSearch() {
      this.planName = "";
      this.planType = "";
      this.planCreater = "";
      this.create_time = "";
      this.state = "";
      this.shaixuan();
    },
    // 刷新重新获取数据
    getDataAgain(currentPage) {
      this.planNum = "";
      this.keyNum++;
      if (currentPage) {
        this.currentPage = currentPage;
      }
      this.$loading(this.util.opload);
      (this.time1 = ""),
        (this.citykind = ""),
        (this.wuyeVal = ""),
        (this.wechat = ""),
        // this.taskid = "",
        (this.client = ""),
        (this.shopper = ""),
        // this.chosecon = "",
        this.util
          .get(this.HOST + "/User/ccList", {
            start_time: undefined,
            end_time: undefined,
            city_id: undefined,
            store_id: undefined,
            full: undefined,
            chen: undefined,
            contacts: undefined,
            assignor: undefined,
            complaints: 0,
            status: 20,
            residential_id: undefined,
            wechat: undefined,
            page: this.currentPage,
            admin_id: 0,
            limit: 20,
            type: 0,
            types: 0,
          })
          .then((res) => {
            if (res.code == 200) {
              this.count = res.data.count;
              this.planData = res.data.data;
              this.$loading().close();
              this.getLists();
            } else {
              this.$loading().close();
              this.$message({
                showClose: true,
                message: res.msg,
                type: "error",
                duration: 1200,
                offset: 90,
              });
            }
          });
    },
    // 任务类型
    gettasks() {
      this.util.get(this.HOST + "/Common/service", {}).then((res) => {
        if (res.code == 200) {
          this.tasks = res.data;
          //   this.getclient();
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    },
    // 列表
    getLists() {
      this.$loading(this.util.opload);
      if (this.create_time == null || this.create_time == "") {
        this.create_time = "";
      }
      let time_start = this.util.datatime(this.create_time[0]);
      let time_end = this.util.datatime(this.create_time[1]);
      if (time_start == "NaN-NaN-NaN NaN:NaN:NaN") {
        time_start = "";
        time_end = "";
      }
      this.util
        .get(this.HOST + "/Diagnostic/basic", {
          page: this.currentPage,
          limit: 20,
          title: this.planName,
          name: this.planCreater,
          category: this.planType,
          state: this.state,
          time_start,
          time_end,
        })
        .then((res) => {
          // return
          if (res.code == 200) {
            this.count = res.data.count;
            this.planData = res.data.data;
            this.$loading().close();
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 分页
    handleCurrentChange() {
      this.$loading(this.util.opload);
      if (this.create_time == null || this.create_time == "") {
        this.create_time = "";
      }
      let time_start = this.util.datatime(this.create_time[0]);
      let time_end = this.util.datatime(this.create_time[1]);
      if (time_start == "NaN-NaN-NaN NaN:NaN:NaN") {
        time_start = "";
        time_end = "";
      }
      this.util
        .get(this.HOST + "/Diagnostic/basic", {
          page: this.currentPage,
          limit: 20,
          title: this.planName,
          name: this.planCreater,
          category: this.planType,
          state: this.state,
          time_start,
          time_end,
        })
        .then((res) => {
          if (res.code == 200) {
            this.count = res.data.count;
            this.planData = res.data.data;
            this.$loading().close();
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 弹框
    diagopen() {
      this.keyNum++;
      this.isdiag = true;
      this.$store.commit("setDialog", true);
      this.dialogTableVisible = true;
    },
    diagclose() {
      this.keyNum++;
      this.$store.commit("setDialog", false);
      this.isdiag = false;
      this.dialogTableVisible = false;
    },
    shaixuan() {
      this.$loading(this.util.opload);
      if (this.create_time == null || this.create_time == "") {
        this.create_time = "";
      }
      let time_start = this.util.datatime(this.create_time[0]);
      let time_end = this.util.datatime(this.create_time[1]);
      if (time_start == "NaN-NaN-NaN NaN:NaN:NaN") {
        time_start = "";
        time_end = "";
      }
      this.util
        .get(this.HOST + "/Diagnostic/basic", {
          page: this.currentPage,
          limit: 20,
          title: this.planName,
          name: this.planCreater,
          category: this.planType,
          state: this.state,
          time_start,
          time_end,
        })
        .then((res) => {
          if (res.code == 200) {
            this.count = res.data.count;
            this.planData = res.data.data;
            this.$loading().close();
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    changeStatus(row, num) {
      //console.log(row);
      this.util
        .get(this.HOST + "/Diagnostic/Enable", {
          plan_id: row.plan_id,
          state: num,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              showClose: true,
              message: "状态改变成功！",
              type: "success",
              duration: 1200,
              offset: 90,
            });
            this.$loading().close();
            this.shaixuan();
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    copyClose() {
      this.dialogTableVisible2 = false;
      this.planNum = "";
    },
    cancelCopy() {
      this.dialogTableVisible2 = false;
      this.planNum = "";
    },
    submitCopy() {
      this.$loading(this.util.opload);
      this.util
        .get(this.HOST + "/Diagnostic/MasterCreationInfo", {
          number: this.planNum,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$loading().close();
            this.dialogTableVisible2 = false;
            this.$store.commit("setNumber", this.planNum);
            this.$store.commit("setDialog", true);
            this.dialogname = "planDetCopy";
          } else {
            this.$loading().close();
            this.dialogTableVisible2 = false;
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
  },
  // 物业
  computed: {
    NewItems() {
      var _this = this;
      var NewItems = [];
      this.items.map(function (item) {
        if (item.username.search(_this.wuyeVal) != -1) {
          NewItems.push(item);
        }
      });
      return NewItems;
    },
    // 判断弹出框
    getStoreItem() {
      this.dialogTableVisible = this.$store.state.dialogTableVisibleAdd;
      this.isdiag = this.$store.state.dialogTableVisibleAdd;
    },
    // 判断处理成功
    getStoreTask() {
      // if (this.$store.state.task == 1) {
      // this.planData = [];
      // this.getLists("fen");
      // this.shaixuan()
      // }
    },
    getworks() {
      this.works = this.$store.state.works;
    },
  },
  watch: {
    getStoreItem() {},
    getStoreTask() {},
  },
  components: {
    AddPlan: () => import("@/components/addPlan.vue"),
    EditPlan: () => import("@/components/editPlan.vue"),
    PlanDet: () => import("@/components/planDet.vue"),
    PlanDetCopy: () => import("@/components/planDetCopy.vue"),
  },
};
</script>
<style scoped>
.first {
  margin: 0 auto;
  background: white;
  /* padding: 10px 30px; */
  padding: 15px 0 0 30px;
  border-radius: 8px;
}
.el-main {
  padding: 10px;
}
.title {
  font-weight: 400;
  color: #1f2f3d;
  font-size: 18px;
  background: none;
  width: 93%;
}
p {
  margin: 0;
}
.blocks2 {
  margin-right: 20px;
  margin-top: 10px;
}
.t2span {
  width: 64px;
}
.conplles {
  height: 76px;
}
.conplles2 {
  height: 0;
}
.el-input {
  width: auto;
}
li {
  list-style-type: none;
}
.el-dialog {
  width: 85%;
}
.datePicker {
  width: 256px;
  height: 32px !important;
  line-height: 32px !important;
}
.littleBox /deep/ input {
  height: 32px !important;
  line-height: 32px !important;
}
div /deep/ .avue-crud__menu {
  display: none;
}
.blocks2 {
  margin-right: 20px;
  margin-top: 10px;
}
.smallTit {
  font-size: 14px;
}
.two {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
